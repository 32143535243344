/* 
 * MONSTSERRAT/NOTO SANS JAPANESE
 *
 * Below we are combining both MONSTSERRAT and NOTO SANS JAPANESE into one
 * font but specifying the unicode range so that MONSTSERRAT is just for latin
 * glyphs, and NOTO SANS JAPANESE for Japanese glyphs. This means we can apply
 * the two different fonts depending on the type of unicode being displayed
 * as opposed to having to apply it on the HTML level.
 * @src https://web.dev/reduce-webfont-size/#unicode-range-subsetting
 * @src https://stackoverflow.com/a/49490145
 * @src https://google-webfonts-helper.herokuapp.com/fonts
 *
 * Also providing support for modern browsers/practical level of browser support
 * We can add more i.e. TTF, OTF, EOT etc... but no sure if it's needed
 * @src https://css-tricks.com/snippets/css/using-font-face/
 *
 * TODO: check optimization over loading the font files.
 */


/* 
 * NOTO SANS JAPANESE
 * @src https://fonts.google.com/specimen/Noto+Sans+JP
 */
/* noto-sans-jp-300 - latin */
@font-face {
  font-family: 'Noto Sans Jp';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans Japanese Light'), local('NotoSansJapanese-Light'),
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+3000-9FFF, U+ff??; /* Japanese glyphs */
}

/* noto-sans-jp-regular - latin */
@font-face {
  font-family: 'Noto Sans Jp';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Japanese Regular'), local('NotoSansJapanese-Regular'),
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+3000-9FFF, U+ff??; /* Japanese glyphs */
}

/* noto-sans-jp-500 - latin */
@font-face {
  font-family: 'Noto Sans Jp';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Japanese Medium'), local('NotoSansJapanese-Medium'),
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+3000-9FFF, U+ff??; /* Japanese glyphs */
}

/* noto-sans-jp-700 - latin */
@font-face {
  font-family: 'Noto Sans Jp';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Japanese Bold'), local('NotoSansJapanese-Bold'),
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+3000-9FFF, U+ff??; /* Japanese glyphs */
}

/* noto-sans-jp-900 - latin */
@font-face {
  font-family: 'Noto Sans Jp';
  font-style: normal;
  font-weight: 900;
  src: local('Noto Sans Japanese Black'), local('NotoSansJapanese-Black'),
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Noto_Sans_JP/noto-sans-jp-v27-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+3000-9FFF, U+ff??; /* Japanese glyphs */
}
