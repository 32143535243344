$app-main-border: 30px;

$sidebar-width: 12.5rem;
$sidebar-width-collapsed: 4.375rem;
$sidebar-width-collapsed-distance: $sidebar-width - $sidebar-width-collapsed;
$secondary-navigation-height: 3.5rem;

// To make the border radius equal to Mui default button's border radius: 4px
$input-border-radius: 0.25rem;

$simulation-padding: 1.5rem;
// ($simulation-padding * 3) is for margin-left and right on left pane, margin-right on right pane
$simulation-main-without-margins: calc(100% - (#{$sidebar-width} + (#{$simulation-padding} * 3)));

// Why 14.875rem?
// => 12.5rem => sidebar
// => 1.875rem => App.scss -> .fo-app__main padding
// => 0.5rem => ShipmentDetails.scss -> .shipment-details right
// -------
// - 1rem for 16px fixed spacing
$quote-list-minimized-width: calc(((100% - 14.875rem) * 0.442) - 1rem);
$quote-header-margin: 1.563rem;

// header height for chat and notification lists
$popover-list-header-height: 51px;

$shipment-3-pane-left-spacing: 16px;
$shipment-list-minimized-width: 24rem;
$shipment-list-minimized-width-3-pane: 20rem;
$shipment-chat-min-width: 20.5rem;
$shipment-pane-border-radius: 5px;
