@import '../../../assets/styles/colors';
@import '../../../assets/styles/dimensions';
@import '../../../assets//styles/animations';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  background-color: $shippio-blue;
  color: #fff;
  z-index: 1;
  transition: width $animation-speed__side-bar;

  .sidebar-collapsed & {
    width: $sidebar-width-collapsed;

    .sidebar {
      &__shippio-logo {
        &--full {
          opacity: 0;
          pointer-events: none;
        }

        &--mini {
          opacity: 1;
          pointer-events: unset;
        }
      }

      &__nav-list__item {
        .MuiListItemIcon-root {
          // To make icon align center and allow moving animation
          // It's complicated to get the number :(
          margin-left: 9px;
        }

        .MuiListItemText-root {
          opacity: 0;
        }
      }

      &__toggle {
        &__expand{
          opacity: 1;
          transform: translateY(0);
          pointer-events: unset;
        }

        &__collapse {
          opacity: 0;
          pointer-events: none;
        }
      }

      &__shippio-circle {
        transform: translateX(-100%);
        opacity: 0;
      }
    }
  }

  &__toggle {
    $toggle-base-size: 16px;
    // To make icon easier to click
    $toggle-padding: 10px;
    $toggle-size: $toggle-base-size + $toggle-padding * 2;

    position: relative;

    &__expand,
    &__collapse {
      cursor: pointer;
      display: block;
      height: $toggle-size;
      padding: $toggle-padding;

      // Make toggle options disabled
      .sidebar__toggle.sidebar__toggle--is-disabled & {
        pointer-events: none;
        opacity: 0;
      }
    }

    &__expand {
      transform: translateY(100%);
      opacity: 0;
      transition: all $animation-speed__side-bar;
      margin-top: -$toggle-padding;
      margin-bottom: -$toggle-padding;
      pointer-events: none;
      width: 100%;
    }

    &__collapse {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 1;
      transition: opacity $animation-speed__side-bar;
      width: $toggle-size;
    }
  }

  &__shippio-logo {
    height: 64px;
    padding-top: 24px;
    position: relative;

    &--full {
      height: 1.75rem;
      margin: 0 auto;
      opacity: 1;
      display: block;
      transition: opacity calc($animation-speed--side-bar / 2);
    }

    &--mini {
      $logo-size: 40px;

      width: $logo-size;
      height: $logo-size;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      display: block;
      transition: opacity calc($animation-speed--side-bar / 2);
      top: 12px;
      left: calc((#{$sidebar-width-collapsed} - #{$logo-size}) / 2);
    }
  }

  &__menu {
    position: absolute;
    width: 100%;
    z-index: 1;

    overflow: hidden;
  }

  &__shippio-circle {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: auto;
    transition: all $animation-speed__side-bar;

    &.invisible {
      opacity: 0;
    }
  }

  &__nav-list {
    // Overriding the Material UI default padding
    .MuiListItem-root {
      padding: 0.75rem 1rem;
    }

    &__item {
      color: #fff;
      gap: 4px;

      // To narrow the space between the icon and title
      .MuiListItemIcon-root {
        min-width: 0px;
        // For Collapse animation
        transition: margin $animation-speed__side-bar;
        margin-left: 0;
      }

      // To override the Material UI default padding
      .MuiListItemText-root {
        margin: 0;
        // For Collapse animation
        white-space: nowrap;
        opacity: 1;
        transition: opacity $animation-speed__side-bar;
        & span {
          font-size: 0.875rem;
        }
      }

      svg {
        fill: #fff;
        height: 18px;
      }

      &.active {
        background-color: $shippio-dark-blue;

        svg {
          fill: $shippio-green;
        }

        .sidebar__active-border {
          border: 2px solid $shippio-green;
          border-radius: 4px;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
        }
      }

      &:hover {
        background-color: $shippio-dark-blue;
      }

      // For "Support" menu item
      &.MuiListItem-button:hover {
        background-color: $shippio-dark-blue;
      }

      &--is-disabled {
        pointer-events: none;
      }
    }
  }

  &__divider {
    // Override .MuiDivider-root styling with !important
    background-color: rgba(255, 255, 255, 0.8) !important;
    margin: 0 1rem !important;
  }
}
