@import '../../assets/styles/colors';

.error-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  text-align: center;

  img {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.6rem;
  }

  button {
    font-weight: 600;
  }

  svg {
    height: 1rem;
    width: 1rem;
    fill: $white;
  }
}
