@import '~normalize.css/normalize.css';
@import 'src/assets/styles/font-faces';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/colors';

// This will force the browser to calculate the width according to
// the "outer"-width of the div, it means the padding will be
// subtracted from the width.
// Personally I prefer this... thoughts?
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --base-grey-4: #{$base-grey-4}; 
  --base-grey-6: #{$base-grey-6};
  --attention-red-5: #{$attention-red-5};
  --accent-green-4: #{$accent-green-4};
  --attention-yellow-5: #{$attention-yellow-5};
  --primary-navy-7: #{$primary-navy-7};
  --shippio-green: #{$shippio-green};
  --text-dark-grey: #{$text-dark-grey};
  // Add other color variables on the go.
}

// Make the body and the apps outer containers full height
html,
body,
#root,
#root > div {
  height: 100%;
}

html,
body {
  background-color: $light-blue;
}

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Make sure SVGs take the fill of it's parent's color
svg { fill: currentColor }

// Eventually get rid of everything underneath this
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-container {
  display: flex;
}

.modal {
  position: absolute;
  top: 20%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  margin-right: -50%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  width: 300px;
  outline: none;
  padding: 20px;

  h4 {
    margin-top: 0px;
  }

  form input {
    width: 100%;
    border: 1px solid #ded7d7;
    border-radius: 3px;
    box-sizing : border-box;
    padding: 8px 5px;
    margin-bottom: 5px;
  }
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button:first-child {
    margin-right: 5px;
  }
}

legend {
  margin-bottom: 15px;
}

.link {
  color: $shippio-green;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// Currently used for the ExcelDownload and BulkInvoiceDownload notification icons
// https://github.com/shippio/front-office/pull/510#discussion_r656975002
.notification-context {
  &__icon {
    &--is-completed {
      color: $shippio-green; // #2AE61E
    }

    &--is-error {
      color: $error-text; // #D53612
    }
  }
}
